export default {
  data() {
    return {
      formEdit: {
        isCBAContract: true,
        contractStatusTypeId: "",
        isCollectProxy: true,
        collectProxyList: [],
        collectReferenceGenerateCompanyList: [],
      },
    };
  },
};
