<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="CBA加入情報編集（運搬）" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>
        <div class="l-contents l-box">
          <div class="l-box">
            <h3 class="c-lead icon">契約情報</h3>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label required">
              <label>代理登録</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols">
                <div class="c-radio">
                  <input
                    id="auto-update-true"
                    type="radio"
                    name="auto-update"
                    :value="true"
                    v-model="formEdit.isCollectProxy"
                  />
                  <label class="c-radio__label" for="auto-update-true">
                    <span class="c-radio__box"></span>
                    有り
                  </label>
                </div>
                <div class="c-radio">
                  <input
                    id="auto-update-false"
                    type="radio"
                    name="auto-update"
                    :value="false"
                    v-model="formEdit.isCollectProxy"
                    @input="clearForm()"
                  />
                  <label class="c-radio__label" for="auto-update-false">
                    <span class="c-radio__box"></span>
                    無し
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label></label>
            </div>
            <div class="c-inputWrap__items">
              <div
                  class="c-btn primary small l-area btn-mid"
                  @click="isDisplaySelectForReference = true"
              >
                マニフェスト閲覧対象排出事業者選択
              </div>
              <span v-if="selectedGeneratorsForReference.length">
                {{ selectedGeneratorsForReference.map(g => g.name).join("、") }}
              </span>
            </div>
          </div>
          <template v-if="formEdit.isCollectProxy">
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label></label>
              </div>
              <div class="c-inputWrap__items">
                <div
                    :class="{ disabled: !selectedGeneratorsForReference.length }"
                    class="c-btn primary small l-area btn-mid"
                    @click="isDisplaySelect = true"
                >
                  代理予約排出事業者選択
                </div>
                <span v-if="selectedGeneratorsForRegist.length">
                  {{ selectedGeneratorsForRegist.map(g => g.name).join("、") }}
                </span>
              </div>
            </div>
          </template>
        </div>

        <div class="l-btns" style="margin-top: 20rem">
          <div class="c-btn secondary small" @click="backHandler()">戻る</div>
          <div class="c-btn primary small" @click="isDisplayConfirm = true">登録</div>
        </div>
      </div>
      <!--排出事業者一覧のモーダル-->
      <div v-if="isDisplaySelect" class="c-modal lg">
        <div class="c-modal__overlay"></div>
        <div class="c-modal__content">
          <div class="c-modal__head">
            <h5 class="c-lead-s">代理予約排出事業者選択</h5>
            <span
              class="c-modal__head__close"
              @click="closeModalHandler()"
            ></span>
          </div>
          <div class="c-modal__body">
            <div>
              <div class="c-modal__body__list">
                <div
                  class="c-checkbox"
                  v-for="generateCompany in selectedGeneratorsForReference"
                  :key="generateCompany.id"
                >
                  <input
                    :id="generateCompany.id"
                    type="checkbox"
                    name="generate-company"
                    :value="generateCompany"
                    v-model="selectingGeneratorsForRegist"
                  />
                  <label class="c-checkbox__label" :for="generateCompany.id">
                    <span class="c-checkbox__box"></span>
                    {{ dottedText(generateCompany.name, 23) }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="c-modal__foot">
            <div
              class="c-btn secondary small"
              @click="closeModalHandler()"
            >
              キャンセル
            </div>
            <div class="c-btn primary small" @click="selectHandler()">設定</div>
          </div>
        </div>
      </div>
      <div v-if="isDisplaySelectForReference" class="c-modal lg">
        <div class="c-modal__overlay"></div>
        <div class="c-modal__content">
          <div class="c-modal__head">
            <h5 class="c-lead-s">マニフェスト閲覧対象排出事業者選択</h5>
            <span
              class="c-modal__head__close"
              @click="closeReferenceModalHandler()"
            ></span>
          </div>
          <div class="c-modal__body">
            <div>
              <div class="c-modal__body__list">
                <div
                  class="c-checkbox"
                  v-for="generateCompany in generateCompanyList"
                  :key="generateCompany.id"
                >
                  <input
                    :id="generateCompany.id"
                    type="checkbox"
                    name="generate-company"
                    :value="generateCompany"
                    v-model="selectingGeneratorForReference"
                  />
                  <label class="c-checkbox__label" :for="generateCompany.id">
                    <span class="c-checkbox__box"></span>
                    {{ dottedText(generateCompany.name, 23) }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="c-modal__foot">
            <div
              class="c-btn secondary small"
              @click="closeReferenceModalHandler()"
            >
              キャンセル
            </div>
            <div class="c-btn primary small" @click="selectReferenceModalHandler()">設定</div>
          </div>
        </div>
      </div>
      <!--登録確認のモーダル-->
      <div v-if="isDisplayConfirm" class="c-modal md">
        <div class="c-modal__overlay"></div>
        <div class="c-modal__content">
          <div class="c-modal__body">
            CBA加入情報（運搬）を編集します。<br />
            よろしいですか？
          </div>
          <ModalFooterButtons :processing="processing">
            <div class="c-btn secondary small" @click="isDisplayConfirm = false">戻る</div>
            <div class="c-btn primary small" @click="confirmHandler()">はい</div>
          </ModalFooterButtons>
        </div>
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import common from "@/mixin/common";
import validation from "@/mixin/validation";
import formValueEdit from "../mixins/formValueEdit";
import callApi from "../mixins/callApi";
import { API_URL } from "@/const";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";

export default {
  name: "collector-cbacontract-edit",
  components: {ModalFooterButtons},
  mixins: [common, validation, formValueEdit, callApi],
  data() {
    return {
      isDisplaySelect: false,
      isDisplayConfirm: false,
      isDisplaySelectForReference: false,
      errMsgs: [],
      selectingGeneratorsForRegist: [],
      selectedGeneratorsForRegist: [],
      selectingGeneratorForReference: [],
      selectedGeneratorsForReference: [],
      processing: false,
    };
  },
  methods: {
    postForm() {
      this.errMsgs = [];
      this.processing = true; // 2重submit防止
      axios
        .put(API_URL.COLLECTOR.CBACONTRACT_EDIT + this.$route.params.id, this.formEdit)
        .then(() => {
          this.$router.push("/operate");
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.processing = false; // 2重submit解除
          this.isDisplayConfirm = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    clearForm() {
      this.selectingGeneratorsForRegist = [];
      this.selectedGeneratorsForRegist = [];
      this.form.collectProxyList = [];
      this.form.collectReferenceGenerateCompanyList = [];
    },
    closeModalHandler() {
      this.isDisplaySelect = false  
      this.selectingGeneratorsForRegist = this.selectedGeneratorsForRegist;
    },
    // モーダルの排出事業者選択押下
    selectHandler() {
      this.selectedGeneratorsForRegist = this.selectingGeneratorsForRegist;
      this.isDisplaySelect = false;
    },
    closeReferenceModalHandler() {
      this.isDisplaySelectForReference = false  
      this.selectingGeneratorForReference = this.selectedGeneratorsForReference;
    },
    selectReferenceModalHandler() {
      this.selectedGeneratorsForReference = this.selectingGeneratorForReference;
      this.isDisplaySelectForReference = false;
      // マニフェスト閲覧対象排出事業者選択に含まれない要素を代理予約排出事業者選択から削除
      this.selectedGeneratorsForRegist = this.selectedGeneratorsForRegist.filter(e => this.selectedGeneratorsForReference.findIndex(e1 => e1.id == e.id) >= 0)
      this.selectingGeneratorsForRegist = this.selectedGeneratorsForRegist
    },
    // モーダルの登録押下
    confirmHandler() {
      this.errMsgs = [];
      this.formEdit.collectProxyList = [];
      this.formEdit.collectReferenceGenerateCompanyList = [];
      this.selectedGeneratorsForRegist.forEach(generator => {
        this.formEdit.collectProxyList.push({ id: parseInt(generator.id) });
      });
      this.formEdit.collectReferenceGenerateCompanyList = [];
      this.selectedGeneratorsForReference.forEach(generator => {
        this.formEdit.collectReferenceGenerateCompanyList.push({ id: parseInt(generator.id) });
      });
      this.postForm();
    },
    // 戻る押下（遷移元に戻る）
    backHandler() {
      this.$router.go(-1);
    },
  },
  mounted() {
    const collectorId = this.$route.params.id;
    this.getGeneratorChoiceApi();
    this.getCollectorApi(collectorId);
  },
};
</script>
<style scoped>
.btn-mid {
  max-width: 26rem;
  margin-bottom: 0;
}
</style>
